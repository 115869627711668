import httprequest from "./httprequest";

// getters

const GetOpenChats = (uuid) => {
  return httprequest.get("/api/Chat/" + uuid);
};

const GetAContact = (ChatKey) => {
  return httprequest.get("/api/Chat/GetAContact/?ChatKey=" + ChatKey);
};

const GetContacts = (uuid) => {
  return httprequest.get("/api/Chat/Contacts/?uuid=" + uuid);
};

const UserData = (uuid) => {
  return httprequest.get("/api/Chat/UserData/?uuid=" + uuid);
};

const ChatsData = (uuid, contactid) => {
  return httprequest.get(
    "/api/Chat/ChatsData/?uuid=" + uuid + "&contactid=" + contactid
  );
};

const getTimeToken = (uuid, selecteduser) => {
  return httprequest.get(
    "/api/Chat/timetoken/?uuid=" + uuid + "&selecteduser=" + selecteduser
  );
};

const getAllAssets = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/Assets/" + params);
};

const getCrewAssetList = (componentAssets) => {
  return httprequest.get(
    "/api/Assets/?noCrew=true&componentAssets=" + componentAssets
  );
};

const getAssets = (treeview, PageNumber, PageRows) => {
  return httprequest.get(
    `/api/Assets/?treeview=${treeview}&PageNumber=${PageNumber}&PageRows=${PageRows}`
  );
};

const getMeterList = () => {
  return httprequest.get("/api/Meter/?list=true");
};

const getMeter = (meterId) => {
  return httprequest.get("/api/Meter/" + meterId);
};

const getMeterLogs = (meterId) => {
  return httprequest.get("/api/Meter/readings/" + meterId);
};

const getMeterParents = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/Meter/parents/" + params);
};

const getMetersFromAsset = (id) => {
  return httprequest.get("/api/Meter/asset/" + id);
};

const getAssetFromCode = (code) => {
  return httprequest.get("/api/MobileAssets/" + code);
};

const getAllCrews = (active) => {
  const params = active === true ? "&active=true" : "";
  return httprequest.get("/api/Crews/?getall=true" + params);
};

const getCrewAssets = (crewName, crewId) => {
  return httprequest.get(
    `/api/CrewAssets/?crewname=${crewName}&crewid=${crewId}`
  );
};

const getCrewMembers = (Name, crewId) => {
  return httprequest.get(`/api/CrewMembers/?Name=${Name}&crewid=${crewId}`);
};

const getAllUsers = () => {
  return httprequest.get("/api/MobileUser/?active=true");
};

const getAllPersonnel = () => {
  return httprequest.get("/api/Personnel/?details=true");
};

const getAttachments = (id, rootFolder) => {
  return httprequest.get(`api/Attachment/?rootFolder=${rootFolder}&id=${id}`);
};

const getLeads = () => {
  return httprequest.get("/api/Personnel/?isLead=true");
};

const getLabourPersonnel = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/Personnel/" + params);
};

const getLabourRateType = () => {
  return httprequest.get("/api/LabourRateType/");
};

const getNonUserPersonnel = () => {
  return httprequest.get("/api/Personnel/?nonUsers=true");
};

const getAllPersonnelCrew = (crewId) => {
  return httprequest.get("/api/Personnel/?CrewId=" + crewId);
};

const getAllSettingsUsers = () => {
  return httprequest.get("/api/UserDetails/?userDetails=true");
};

const getAssertFromParentCode = (parentCode) => {
  return httprequest.get(`/api/MobileAssets/?parentCode=${parentCode}`);
};

const getAllAssetLogs = (id) => {
  return httprequest.get("/api/AssetLogs/?assetId=" + id);
};

const getAllAssetRecentActivityLogs = (id) => {
  return httprequest.get("/api/AssetLogs/RecentActivities/?assetId=" + id);
};

const getAssetFromCodeMain = (id) => {
  return httprequest.get("/api/Assets/" + id);
};

const getCostCenter = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/CostCenter/" + params);
};

const getCostSettlingDepartment = (params) => {
  return httprequest.get("/api/CostSettlingDepartment/" + params);
};

const getFailureArea = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/FailureArea/" + params);
};

const getFailureType = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/FailureType/" + params);
};

const getFailureCause = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/FailureCause/" + params);
};

const getFailureRepairType = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/FailureRepairType/" + params);
};

const getSubTaskTypes = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/SubTaskTypes/" + params);
};

const getWorkOrderProblems = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkOrderProblem/" + params);
};

const getWorkOrderCauses = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkOrderCause/" + params);
};

const getWorkOrderActions = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkOrderAction/" + params);
};

const getWorkOrderBoards = (id) => {
  return httprequest.get("/api/WorkOrderBoard/" + id);
};

const getWorkOrderStatuses = () => {
  return httprequest.get("/api/WorkOrderStatus/?noCancel=true");
};

const getCustomWorkOrderStatuses = (params) => {
  return httprequest.get("/api/CustomWorkOrderStatus/" + params);
};

const getDetailedCustomWorkOrderStatuses = () => {
  return httprequest.get("/api/CustomWorkOrderStatus/?details=true");
};

const getWorkOrderTypes = () => {
  return httprequest.get("/api/Workordertype/?active=true");
};

const getMaintenanceTypes = (workOrderTypeId) => {
  return httprequest.get(
    "/api/MaintenanceType/?workOrderType=" + workOrderTypeId
  );
};

const getMaintenancePlant = () => {
  return httprequest.get("/api/Warehouses/");
};

const getAllProjects = (active) => {
  if (active === undefined) {
    active = false;
  }
  return httprequest.get("/api/Projects/?active=" + active);
};

const getUserRoles = (params) => {
  return httprequest.get("/api/UserRoles/" + params);
};

const getUserProfiles = () => {
  return httprequest.get("/api/Profile/");
};

const getOperationsfromgroup = (group) => {
  return httprequest.get(`api/MobileTasks/?group=${group}`);
};

const getWorkOrderLabor = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("api/WorkLabour/" + params);
};

const getWorkOrderParts = (taskId) => {
  return httprequest.get(`api/WorkPart/${taskId}`);
};

const getAttachmentFile = (attachmentId) => {
  return httprequest.get(`api/Attachment/${attachmentId}`, {
    responseType: "blob",
  });
};

const getLogo = () => {
  return httprequest.get("api/TenantSettings/logo", {
    responseType: "blob",
  });
};

const getLoginBackground = () => {
  return httprequest.get("api/TenantSettings/background", {
    responseType: "blob",
  });
};

const getWorkOrderFailure = (workOrderId) => {
  return httprequest.get(`api/WorkOrderFailures/${workOrderId}`);
};

const getWorkOrderFleet = (workOrderCode) => {
  return httprequest.get(`api/FleetTracker/?workOrderCode=${workOrderCode}`);
};

const getWorkNotes = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkNotes/" + params);
};

const getWorkRequests = (userId) => {
  return httprequest.get(`api/WorkRequest/?userId=${userId}`);
};

const getWorkRequestList = (payload) => {
  return httprequest.post("api/WorkRequest/list/", payload);
};

const getWorkOrderMeterReadings = (workOrderId) => {
  return httprequest.get(
    `api/WorkOrderMeterReadings/?workOrderId=${workOrderId}`
  );
};

const getWorkOrderCards = (userId, personId) => {
  return httprequest.get(
    "api/WorkOrder/?userId=" + userId + "&personId=" + personId
  );
};

const getWorkOrderLists = (payload) => {
  return httprequest.post("api/WorkOrder/list/", payload);
};

const getWorkOrderCalendarList = () => {
  return httprequest.get("api/WorkOrder/calendar");
};

const getWorkOrderCalendarListPms = () => {
  return httprequest.get("api/WorkOrder/calendarpms");
};

const getWorkOrder = (id) => {
  return httprequest.get("/api/WorkOrder/" + id);
};

const getWorkOrderLog = (id) => {
  return httprequest.get("/api/WorkOrderLog/" + id);
};

const getWorkRequestLog = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkRequestLog/" + params);
};

const getPartsConsumption = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/PartsConsumption/" + params);
};

const getAssetMovement = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/AssetMovement/" + params);
};

const getWorkOrderCompletion = (id) => {
  return httprequest.get("/api/WorkOrder/completion/" + id);
};

const getWorkOrderSubTasks = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkOrderSubTasks/" + params);
};

const getWorkOrdersPrint = (ids) => {
  return httprequest.post("/api/WorkOrderPrint/", ids);
};

const executeSSO = () => {
  return httprequest.get("/api/ssosaml");
};

const consumeSSO = () => {
  return httprequest.get("/api/samlconsume");
};

const signIn = (credentials) => {
  return httprequest.post("/auth/signin", credentials);
};

const samlSignIn = () => {
  return httprequest.get("/auth/SamlSSO/signin");
};

const logout = () => {
  return httprequest.post("/auth/signin/logout");
};

const userAuthID = () => {
  return httprequest.get("/auth/signin/");
};

const getAllSensors = (sensorid) => {
  return httprequest.get("/SensorMessage/" + sensorid);
};

const getAxuimSensors = () => {
  return httprequest.get("/AxiumSensor/");
};

const getSensors = (param) => {
  return httprequest.get("/api/Sensor/" + param);
};

const getSensorGateways = () => {
  return httprequest.get("/api/SensorGateways/?detail=true");
};

const getSensor = (ProductId) => {
  return httprequest.get("/api/Sensor/" + ProductId);
};

const getSensorGateway = (ProductId) => {
  return httprequest.get("/api/SensorGateways/" + ProductId);
};

const getSensorNetwork = (NetworkId) => {
  const params = NetworkId !== undefined ? NetworkId : "";
  return httprequest.get("/api/SensorNetwork/" + params);
};

const getSensorUnits = (params) => {
  return httprequest.get("/api/SensorUnits/" + params);
};

const Dashboard = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/Dashboards/" + params);
};

const GetDashboardSettings = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/Dashboards/settings/" + params);
};

const getDashboard = (dashboardId) => {
  return httprequest.get(`/SensorMessage/dashboard/${dashboardId}`);
};

const getSensorWidgetData = (dashboardWidgetId) => {
  return httprequest.get(`/SensorMessage/widget/${dashboardWidgetId}`);
};

const getTenant = () => {
  return httprequest.get("/api/TenantInfo/");
};

const getTenantNav = (userRoleId, profile) => {
  return httprequest.get(
    "/api/UserSubModules/" + userRoleId + "?profile=" + profile
  );
};

const getTenantSubModule = (id) => {
  const params = id !== undefined ? id : "";
  return httprequest.get("/api/TenantSubModules/" + params);
};

const getUserSubModule = (id, userRoleId) => {
  return httprequest.get(
    "/api/UserSubModules/?subModuleId=" + id + "&userRoleId=" + userRoleId
  );
};

const getGatwaysList = (networkCode) => {
  return httprequest.get(
    "/api/SensorGateways/Gatways/?networkCode=" + networkCode
  );
};

const getAllMaintenancePlans = () => {
  return httprequest.get("/api/MaintenancePlans/");
};
const getProfileList = () => {
  return httprequest.get("/api/Profile");
};

const getAMaintenancePlan = (id) => {
  return httprequest.get("/api/MaintenancePlans/" + id);
};

const GetChatUserList = (id) => {
  return httprequest.get(`/api/User/userlist/?id=${id}`);
};

const GetSensorMessages = (sensorId) => {
  return httprequest.get("/api/SensorDataMessage/" + sensorId);
};

const GetGatewayMessages = (gatewayId) => {
  return httprequest.get("/api/GatewayMessage/" + gatewayId);
};

const getAnalyticsDashboard = (dashboardId, roleId) => {
  return httprequest.get(
    "/api/AnalyticsDashboards/" + dashboardId + "/?roleId=" + roleId
  );
};

const getAnalyticsDashboardRecord = (params) => {
  return httprequest.get("/api/AnalyticsDashboards/record/" + params);
};

const getProfileSubModules = (profileId) => {
  return httprequest.get("/api/ProfileSubModules/" + profileId);
};

const getTaskGroups = (active) => {
  return httprequest.get("/api/TaskGroups/?active=" + active);
};

const getTaskGroupsTasks = (id) => {
  return httprequest.get("/api/TaskGroups/tasks/" + id);
};

const getTaskGroupsTask = (id) => {
  return httprequest.get("/api/TaskGroups/task/" + id);
};

const getTaskGroupsTaskSubs = (groupName, id) => {
  return httprequest.get("/api/TaskGroups/task/sub/" + groupName + "/" + id);
};

const getTasksFromGroup = (taskGroups) => {
  return httprequest.post("/api/Tasks/fromGroups", taskGroups);
};
const getTasksFromGroupPms = (taskGroups) => {
  return httprequest.post("/api/Tasks/fromGroups/pm", taskGroups);
};

const getAllMaintenancePlansAssets = (maintenancePlanId) => {
  return httprequest.get("/api/MaintenancePlanAssets/all/" + maintenancePlanId);
};

const getAllMaintenancePlansAssetsQueue = (maintenancePlanId, assetId) => {
  return httprequest.get(
    "/api/MaintenancePlanAssets/allWithTriggerUnit/" +
      maintenancePlanId +
      "/" +
      assetId
  );
};

const getAllPlannedTasks = (maintenancePlanId) => {
  return httprequest.get("/api/PlannedTasks/all/" + maintenancePlanId);
};

const getAllPlannedSubTasks = (plannedTaskId) => {
  return httprequest.get("/api/PlannedSubTasks/all/" + plannedTaskId);
};

const getAllAssetClassifications = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/AssetClassifications/all/" + params);
};

const getAllAssetCategories = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/AssetCategory/all" + params);
};

const getAllAssetCategoriesParent = () => {
  return httprequest.get("/api/AssetCategory/all/parent");
};

const getUnitsOfMeasure = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/UnitOfMeasure/all/" + params);
};

const getAllAssetGroup1 = () => {
  return httprequest.get("/api/AssetGroup1/all/");
};

const getAllAssetGroup2 = () => {
  return httprequest.get("/api/AssetGroup2/all/");
};

const getAllLocationCategories = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/LocationCategory/all/" + params);
};

const getAllLocationCategoriesParent = () => {
  return httprequest.get("/api/LocationCategory/all/parent");
};

const getAllCostCenters = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/CostCenter/all/" + params);
};

const getAllSettlingDepartments = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/CostSettlingDepartment/all/" + params);
};
const getAllMaintenanceDepartments = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/MaintenanceDepartment/all/" + params);
};

const getAllWorkOrderTypes = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkOrderType/" + params);
};

const getAllMaintenanceTypes = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/MaintenanceType/" + params);
};

const getAllWorkOrderProblems = () => {
  return httprequest.get("/api/WorkOrderProblems/all");
};

const getAllWorkOrderProblemCauses = () => {
  return httprequest.get("/api/WorkOrderProblemCauses/all");
};
const getAllWorkOrderActions = () => {
  return httprequest.get("/api/WorkOrderActions/all");
};

const getAllPartItemCategories = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/PartItemCategory/all/" + params);
};

const getAllPartItemCategoriesParent = () => {
  return httprequest.get("/api/PartItemCategory/all/parent");
};

const getAllAssetOperatingStatuses = () => {
  return httprequest.get("/api/AssetOperatingStatus/all");
};

const getAllUnitsOfMeasure = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/UnitOfMeasure/all/" + params);
};

const getAllPartCatalogueItem = () => {
  return httprequest.get("/api/PartCatalogueItem/all");
};

const getPartCatalogueItem = (code) => {
  return httprequest.get("/api/PartCatalogueItem/" + code);
};

const getPartsList = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/PartCatalogueItem/all/" + params);
};

const getAllFailureArea = (param) => {
  return httprequest.get("/api/FailureArea/all");
};

const getAllFailureType = (param) => {
  return httprequest.get("/api/FailureType/all");
};

const getAllFailureCause = (param) => {
  return httprequest.get("/api/FailureCause/all");
};

const getAllFailureRepairType = (param) => {
  return httprequest.get("/api/FailureRepairType/all");
};

const getWorkOrderTask = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkOrderTask/" + params);
};
const getAllLocations = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/Locations/all/" + params);
};
const getLinkedSites = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/LinkedSites/" + params);
};
const getLocationFromCode = (code) => {
  return httprequest.get("/api/Locations/" + code);
};
const getLocationFromId = (id) => {
  return httprequest.get("/api/Locations/fromId/" + id);
};

const getWarehouse = (params) => {
  return httprequest.get("/api/Warehouse/" + params);
};

const getWarehouseStock = (params) => {
  return httprequest.get("/api/Stock/" + params);
};

const getMaterialRequests = (params) => {
  return httprequest.get("/api/MaterialRequest/" + params);
};

const getMaterialReceipts = (params) => {
  return httprequest.get("/api/MaterialReceipt/" + params);
};

const getMatRequestPartLocations = (params) => {
  return httprequest.get("/api/MatReqPartLocs/" + params);
};

const getMatReceiptPartLocations = (params) => {
  return httprequest.get("/api/MatRecPartLocs/" + params);
};

const getSerialStock = (params) => {
  return httprequest.get("/api/SerialStock/" + params);
};

const getAllAllAssets = (id) => {
  return httprequest.get("/api/Assets/allAssets/");
};

const getAllMaintenancePlanTypes = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/MaintenancePlanTypes/all/" + params);
};

const getAllMaintenancePlanTriggers = (maintenancePlanId) => {
  return httprequest.get(
    "/api/MaintenancePlanTrigger/all/" + maintenancePlanId
  );
};

const getAllPlannedLabor = (plannedTaskId) => {
  return httprequest.get("/api/PlannedLabor/all/" + plannedTaskId);
};

const getAllPlannedParts = (plannedTaskId) => {
  return httprequest.get("/api/PlannedParts/all/" + plannedTaskId);
};

const getAMaintenancePlanLogs = (id) => {
  return httprequest.get("/api/MaintenancePlans/logs/" + id);
};

const getNewChatsContactList = (id) => {
  return httprequest.get("/api/Chat/users/" + id);
};

const getNewChatsFriendList = (id) => {
  return httprequest.get("/api/Chat/all/threads/" + id);
};

const getUiKeys = () => {
  return httprequest.get("/api/UiKeys/");
};

const getNotifications = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/NotificationsPanel/" + params);
};

const getAllSupplierCategories = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/SupplierCategory/all/" + params);
};

const getSupplier = (code) => {
  code = code !== undefined ? code : "";
  return httprequest.get("/api/Suppliers/" + code);
};

const getSuppliersList = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/Suppliers/all/" + params);
};

const getDashboardMapPoints = (id) => {
  return httprequest.get("/api/DashboardsWidget/MapPoints/" + id);
};

const getDashboardWidget = (id) => {
  return httprequest.get("/api/DashboardsWidget/" + id);
};

const getTimezones = () => {
  return httprequest.get("/api/Timezone/");
};

const getWorkflowTypes = (submoduleId) => {
  const params = submoduleId !== undefined ? submoduleId : "";
  return httprequest.get("/api/WorkflowTypes/" + params);
};

const getWorkflowTriggers = (workFlowTypeId) => {
  const params = workFlowTypeId !== undefined ? workFlowTypeId : "";
  return httprequest.get("/api/WorkflowTrigger/" + params);
};

const getFloorPlanPinTypes = () => {
  return httprequest.get("/api/FloorPlanPinTypes/");
};

const getDashboardWidgetTypes = () => {
  return httprequest.get("/api/DashboardWidgetTypes/");
};

const getWorkflows = (params) => {
  const param = params !== undefined ? params : "";
  return httprequest.get("/api/Workflow/" + param);
};

const getWorkflowActions = (details) => {
  const params = details !== undefined ? details : "";
  return httprequest.get("/api/WorkflowActions/" + params);
};

const getWorkflowConditions = () => {
  return httprequest.get("/api/WorkFlowConditions/");
};

// post

const acknowledgeSensorAlert = (params, payload) => {
  const param = params !== undefined ? params : "";
  return httprequest.post("/api/SensorAlert/" + param, payload);
};

const createAnalyticsDashboard = (payload) => {
  return httprequest.post("/api/AnalyticsDashboards/add/", payload);
};

const addStock = (payload) => {
  return httprequest.post("/api/Stock/", payload);
};

const addSerialStock = (payload) => {
  return httprequest.post("/api/SerialStock/", payload);
};

const createAttachment = (rootFolder, id, fileName, payload, type) => {
  return httprequest.post(
    `/api/Attachment/?id=${id}&fileName=${fileName}`,
    payload,
    {
      headers: {
        "Content-Type": type,
        description: new Date().toLocaleDateString(),
        rootFolder: rootFolder,
      },
    }
  );
};

const createCrew = (payload) => {
  return httprequest.post("/api/Crews/", payload);
};

const getChat = (payload) => {
  return httprequest.post("/api/Chat/", payload);
};

const createCrewAsset = (payload) => {
  return httprequest.post("/api/CrewAssets/", payload);
};

const createCrewMembers = (payload) => {
  return httprequest.post("/api/CrewMembers/", payload);
};

const createCustomWorkOrderStatuses = (payload) => {
  return httprequest.post("/api/CustomWorkOrderStatus/", payload);
};

const addLinkedSites = (payload) => {
  return httprequest.post("/api/LinkedSites/", payload);
};

const createWorkOrderSubTasks = (payload) => {
  return httprequest.post("/api/WorkOrderSubTasks/", payload);
};

const createWorkOrderTasks = (workOrderId, payload) => {
  return httprequest.post("/api/WorkOrderTask/?woId=" + workOrderId, payload);
};

const postValidateCredentials = (credentials) => {
  return httprequest.post("/auth/Validate/", credentials);
};

const postHandleCredentials = (userId) => {
  return httprequest.post(
    "/auth/HandleCredentials/emailReset?userID=" + userId
  );
};

const postAdminResetCredentials = (body) => {
  return httprequest.post("/auth/HandleCredentials/", body);
};

const createWorkNotes = (note) => {
  return httprequest.post(`/api/WorkNotes/`, note);
};

const createWorkOrder = (payload) => {
  return httprequest.post("/api/WorkOrder/", payload);
};

const generateWorkOrder = (userId) => {
  return httprequest.post("/api/WorkOrder/generate/?userId=" + userId);
};

const createWorkOrderRequest = (requestId, payld) => {
  const payload = { ...payld };
  delete payload.estimatedDurationHours;
  delete payload.estimatedDurationMinutes;
  delete payload.incidentDate;
  delete payload.jobDetails;
  delete payload.groupTaskDescription;

  return httprequest.post(
    "/api/HandleWorkRequest/?workRequestID=" + requestId,
    payload
  );
};

const createWorkOrderLabor = (payload) => {
  return httprequest.post("/api/WorkLabour/", payload);
};

const createWorkOrderPart = (payload) => {
  return httprequest.post("/api/WorkPart/", payload);
};

const createWorkOrderPartWh = (payload) => {
  return httprequest.post("/api/WorkPart/whParts", payload);
};

const createPerson = (payload) => {
  payload.name = payload.firstName + " " + payload.lastName;
  return httprequest.post("/api/Personnel/", payload);
};

const createUser = (payload) => {
  return httprequest.post("/api/UserDetails/", payload);
};

const createWorkOrderFleet = (payload) => {
  return httprequest.post("/api/FleetTracker/", payload);
};

const createWorkRequest = (payload) => {
  return httprequest.post("/api/WorkRequest/", payload);
};

const setEnvironment = (environment) => {
  return httprequest.post("/api/Environment/?environment=" + environment);
};

const createUserRole = (payload) => {
  return httprequest.post("/api/UserRoles/", payload);
};

const updateMeterReadings = (payload) => {
  return httprequest.post("/api/WorkOrderMeterReadings/", payload);
};

const PostDashboard = (boardName, site) => {
  return httprequest.post(
    "/api/Dashboards/?boardName=" + boardName + "&site=" + site
  );
};

const PostDashboardWidget = (payload) => {
  return httprequest.post("/api/DashboardsWidget/", payload);
};

const PostDashboardWidgetMap = (payload) => {
  return httprequest.post("/api/DashboardsWidget/Map/", payload);
};

const PostDashboardMapPoint = (payload) => {
  return httprequest.post("/api/DashboardsWidget/MapPoint/", payload);
};

const createMaintenancePlan = (payload) => {
  return httprequest.post("/api/MaintenancePlans/", payload);
};

const createAsset = (payload) => {
  return httprequest.post("/api/Assets/", payload);
};

const createSensor = (payload) => {
  return httprequest.post("/api/sensor/", payload);
};

const createGateway = (payload) => {
  return httprequest.post("/api/SensorGateways/", payload);
};

const createNetwork = (payload) => {
  return httprequest.post("/api/SensorNetwork/", payload);
};

const createMaintenancePlanAsset = (payload) => {
  return httprequest.post("/api/MaintenancePlanAssets/", payload);
};

const createMeter = (payload) => {
  return httprequest.post("/api/Meter/", payload);
};

const createMeterReading = (id, payload) => {
  return httprequest.post("/api/Meter/readings/" + id, payload);
};

const createPlannedTask = (payload) => {
  return httprequest.post("/api/PlannedTasks/", payload);
};

const createPlannedSubTask = (payload) => {
  return httprequest.post("/api/PlannedSubTasks/", payload);
};

const createAssetClassifications = (payload) => {
  return httprequest.post("/api/AssetClassifications/", payload);
};

const createAssetCategories = (payload) => {
  return httprequest.post("/api/AssetCategory/", payload);
};

const createAssetGroup1 = (payload) => {
  return httprequest.post("/api/AssetGroup1/", payload);
};

const createAssetGroup2 = (payload) => {
  return httprequest.post("/api/AssetGroup2/", payload);
};

const createLocationCategories = (payload) => {
  return httprequest.post("/api/LocationCategory/", payload);
};

const createCostCenter = (payload) => {
  return httprequest.post("/api/CostCenter/", payload);
};

const createSupplierCategory = (payload) => {
  return httprequest.post("/api/SupplierCategory/", payload);
};

const createSettlingDepartments = (payload) => {
  return httprequest.post("/api/CostSettlingDepartment/", payload);
};
const createMaintenanceDepartment = (payload) => {
  return httprequest.post("/api/MaintenanceDepartment/", payload);
};
const createWorkOrderType = (payload) => {
  return httprequest.post("/api/WorkOrderType/", payload);
};
const createMaintenanceType = (payload) => {
  return httprequest.post("/api/MaintenanceType/", payload);
};
const createProject = (payload) => {
  return httprequest.post("/api/Projects", payload);
};

const createWorkOrderProblems = (payload) => {
  return httprequest.post("/api/WorkOrderProblems", payload);
};
const createWorkOrderProblemCauses = (payload) => {
  return httprequest.post("/api/WorkOrderProblemCauses", payload);
};
const createWorkOrderActions = (payload) => {
  return httprequest.post("/api/WorkOrderActions", payload);
};

const createPartItemCategory = (payload) => {
  return httprequest.post("/api/PartItemCategory", payload);
};

const createSuppliers = (payload) => {
  return httprequest.post("/api/Suppliers", payload);
};

const createAssetOperatingStatuses = (payload) => {
  return httprequest.post("/api/AssetOperatingStatus", payload);
};

const createUnitsOfMeasure = (payload) => {
  return httprequest.post("/api/UnitOfMeasure", payload);
};

const createPartCatalogueItem = (payload) => {
  return httprequest.post("/api/PartCatalogueItem", payload);
};

const createFailureArea = (payload) => {
  return httprequest.post("/api/FailureArea", payload);
};

const createFailureType = (payload) => {
  return httprequest.post("/api/FailureType", payload);
};

const createFailureCause = (payload) => {
  return httprequest.post("/api/FailureCause", payload);
};

const createFailureRepairType = (payload) => {
  return httprequest.post("/api/FailureRepairType", payload);
};

const createWorkOrderFailure = (payload) => {
  return httprequest.post("api/WorkOrderFailures", payload);
};

const createTask = (payload, id) => {
  return httprequest.post("/api/Tasks/detailed/" + id, payload);
};

const createTaskGroups = (payload) => {
  return httprequest.post("/api/TaskGroups", payload);
};

const createTaskGroupsTask = (id, payload) => {
  return httprequest.post("/api/TaskGroups/newTask/" + id, payload);
};

const createTaskGroupsTaskSubtasks = (id, payload) => {
  return httprequest.post("/api/TaskGroups/task/newSubTask/" + id, payload);
};

const createTaskGroupsTaskParts = (id, payload) => {
  return httprequest.post("/api/TaskGroups/task/newParts/" + id, payload);
};

const createTaskGroupsTaskLabours = (id, payload) => {
  return httprequest.post("/api/TaskGroups/task/newLabors/" + id, payload);
};

const createNewLocation = (payload) => {
  return httprequest.post("/api/Locations", payload);
};

const createNewWarehouse = (payload) => {
  return httprequest.post("/api/Warehouse", payload);
};

const createMaintenancePlanType = (payload) => {
  return httprequest.post("/api/MaintenancePlanTypes", payload);
};

const createMaintenancePlanTrigger = (payload) => {
  return httprequest.post("/api/MaintenancePlanTrigger", payload);
};

const createMaintenancePlanCalendarTrigger = (payload) => {
  return httprequest.post("/api/MaintenancePlanTrigger/calendar", payload);
};

const createMaintenancePlanMeterTrigger = (payload) => {
  return httprequest.post("/api/MaintenancePlanTrigger/meter", payload);
};

const createMaintenancePlanEventTrigger = (payload) => {
  return httprequest.post("/api/MaintenancePlanTrigger/event", payload);
};

const createPlannedPart = (payload) => {
  return httprequest.post("/api/PlannedParts", payload);
};

const createPlannedLabor = (payload) => {
  return httprequest.post("/api/PlannedLabor", payload);
};

const createNewChatThread = (senderId, receiverId, payload) => {
  return httprequest.post(
    "/api/Chat/new/thread?senderId=" + senderId + "&receiverId=" + receiverId,
    payload
  );
};

const updateNewChatThread = (payload) => {
  return httprequest.post("/api/Chat/update/thread", payload);
};

const updateNewChatState = (payload) => {
  return httprequest.post("/api/Chat/update/state", payload);
};

const updateNewChatStatus = (payload) => {
  return httprequest.post("/api/Chat/update/status", payload);
};

const createWorkflow = (payload) => {
  return httprequest.post("/api/Workflow/", payload);
};

// put

const updateDashboard = (id, name, site) => {
  return httprequest.put(
    "/api/Dashboards/name/" + id + "/?name=" + name + "&site=" + site
  );
};

const updateMaterialRequests = (id, payload) => {
  return httprequest.put("/api/MaterialRequest/" + id, payload);
};

const updateMaterialRequestStatus = (id, status) => {
  return httprequest.put(
    "/api/MaterialRequest/?id=" + id + "&status=" + status
  );
};

const updateMaterialReceipts = (id, payload) => {
  return httprequest.put("/api/MaterialReceipt/" + id, payload);
};

const updateMaterialReceiptStatus = (id, status) => {
  return httprequest.put(
    "/api/MaterialReceipt/?id=" + id + "&status=" + status
  );
};

const updateStock = (payload) => {
  return httprequest.put("/api/Stock/", payload);
};

const updateCustomWorkOrderStatuses = (id, payload) => {
  return httprequest.put("/api/CustomWorkOrderStatus/" + id, payload);
};

const updateWorkOrderLabor = (payload) => {
  payload.workOrderLaborId = payload.id;
  return httprequest.put("/api/WorkLabour/" + payload.id, payload);
};

const updateWorkOrderPart = (payload) => {
  return httprequest.put("/api/WorkPart/" + payload.workOrderPartId, payload);
};

const putNewCredentials = (credentials) => {
  return httprequest.put("/auth/Validate/", credentials);
};

const updateAssetDetails = (id, payload) => {
  return httprequest.put("/api/Assets/Update/" + id, payload);
};

const validateAssetCode = (code, id) => {
  return httprequest.put("/api/Assets/validate/" + code + "/?id=" + id);
};

const updateCrew = (payload) => {
  return httprequest.put("/api/Crews/" + payload.crewId, payload);
};

const updatePerson = (payload) => {
  payload.name = payload.firstName + " " + payload.lastName;
  return httprequest.put("/api/Personnel/" + payload.id, payload);
};

const updateNetwork = (payload) => {
  return httprequest.put("/api/SensorNetwork/" + payload.networkId, payload);
};

const updateGateway = (payload) => {
  return httprequest.put("/api/SensorGateways/" + payload.gatewayId, payload);
};

const gatewayAction = (id, user, reset, update) => {
  return httprequest.put(
    "/api/SensorGateways/action/" +
      id +
      "?user=" +
      user +
      "&reset=" +
      reset +
      "&update=" +
      update
  );
};

const updateSensor = (payload) => {
  return httprequest.put("/api/sensor/" + payload.sensorId, payload);
};

const updateWorkOrderGeneralInfo = (id, payload) => {
  return httprequest.put("/api/WorkOrder/" + id, payload);
};

const updateWorkOrderStatus = (payload) => {
  return httprequest.put("/api/WorkOrder/update-status/", payload);
};

const updateWorkOrderCompletion = (payload) => {
  return httprequest.put("/api/WorkOrder/completion/", payload);
};

const updateUser = (payload) => {
  return httprequest.put("/api/UserDetails/" + payload.tenantUserId, payload);
};

const updateUserProfile = (payload) => {
  return httprequest.put("/api/UserProfile/" + payload.tenantUserId, payload);
};

const updateWorkRequest = (payload) => {
  return httprequest.put("/api/WorkRequest/", payload);
};

const updateWorkOrder = (id, payload) => {
  return httprequest.put("/api/WorkOrderDetailsUpdate/" + id, payload);
};

const updateWorkOrderSubTasks = (payload) => {
  return httprequest.put(
    "/api/WorkOrderSubTasks/" + payload.workOrderSubTaskId,
    payload
  );
};

const completeWorkOrderSubTasks = (payload) => {
  return httprequest.put("/api/WorkOrderSubTasks/complete/", payload);
};

const reorderSubTasks = (payload) => {
  return httprequest.put("/api/TaskGroups/subtasks/reorder/", payload);
};

const reorderWorkOrderSubTasks = (payload) => {
  return httprequest.put("/api/WorkOrderSubTasks/reorder/", payload);
};

const updateUserRole = (payload) => {
  return httprequest.put("/api/UserRoles/" + payload.userRoleId, payload);
};

const NotificationAcknowledgePost = (SensorCode) => {
  return httprequest.post(
    "/api/NotificationAcknowledge/?SensorCode=" + SensorCode
  );
};

const updateMaintenancePlan = (id, payload) => {
  return httprequest.put("/api/MaintenancePlans/" + id, payload);
};
const updateMeters = (id, payload) => {
  return httprequest.put("/api/Meter/" + id, payload);
};

const updatePlannedTask = (id, payload) => {
  return httprequest.put("/api/PlannedTasks/" + id, payload);
};
const updatePlannedSubTasks = (id, payload) => {
  return httprequest.put("/api/PlannedSubTasks/" + id, payload);
};

const updateAssetClassifications = (id, payload) => {
  return httprequest.put("/api/AssetClassifications/" + id, payload);
};

const updateAssetCategories = (id, payload) => {
  return httprequest.put("/api/AssetCategory/" + id, payload);
};

const updateAssetGroup1 = (id, payload) => {
  return httprequest.put("/api/AssetGroup1/" + id, payload);
};

const updateAssetGroup2 = (id, payload) => {
  return httprequest.put("/api/AssetGroup2/" + id, payload);
};

const updateLocationCategories = (id, payload) => {
  return httprequest.put("/api/LocationCategory/" + id, payload);
};

const updateCostCenter = (id, payload) => {
  return httprequest.put("/api/CostCenter/" + id, payload);
};

const updateSupplierCategory = (id, payload) => {
  return httprequest.put("/api/SupplierCategory/" + id, payload);
};

const updateSettlingDepartment = (id, payload) => {
  return httprequest.put("/api/CostSettlingDepartment/" + id, payload);
};

const updateMaintenanceDepartment = (id, payload) => {
  return httprequest.put("/api/MaintenanceDepartment/" + id, payload);
};

const putChat = (id, payload) => {
  return httprequest.put("/api/Chat/" + id, payload);
};

const updateWorkOrderType = (id, payload) => {
  return httprequest.put("/api/WorkOrderType/" + id, payload);
};
const updateMaintenanceType = (id, payload) => {
  return httprequest.put("/api/MaintenanceType/" + id, payload);
};
const updateProject = (id, payload) => {
  return httprequest.put("/api/Projects/" + id, payload);
};

const updateWorkOrderProblems = (id, payload) => {
  return httprequest.put("/api/WorkOrderProblems/" + id, payload);
};

const updateWorkOrderProblemCauses = (id, payload) => {
  return httprequest.put("/api/WorkOrderProblemCauses/" + id, payload);
};

const updateWorkOrderTask = (payload) => {
  payload.responsiblePerson = null;
  return httprequest.put(
    "/api/WorkOrderTask/" + payload.workOrderTaskId,
    payload
  );
};

const batchCompleteWorkOrderTasks = (payload) => {
  return httprequest.put("/api/WorkOrderTask/batchComplete/", payload);
};

const updateWorkOrderActions = (id, payload) => {
  return httprequest.put("/api/WorkOrderActions/" + id, payload);
};

const updatePartItemCategory = (id, payload) => {
  return httprequest.put("/api/PartItemCategory/" + id, payload);
};
const updateAssetOperatingStatus = (id, payload) => {
  return httprequest.put("/api/AssetOperatingStatus/" + id, payload);
};
const updateUnitOfMeasure = (id, payload) => {
  return httprequest.put("/api/UnitOfMeasure/" + id, payload);
};

const updatePartCatalogueItem = (id, payload) => {
  return httprequest.put("/api/PartCatalogueItem/" + id, payload);
};

const updateSupplier = (id, payload) => {
  return httprequest.put("/api/Suppliers/" + id, payload);
};

const updateFailureArea = (id, payload) => {
  return httprequest.put("/api/FailureArea/" + id, payload);
};

const updateFailureType = (id, payload) => {
  return httprequest.put("/api/FailureType/" + id, payload);
};

const updateFailureCause = (id, payload) => {
  return httprequest.put("/api/FailureCause/" + id, payload);
};

const updateFailureRepairType = (id, payload) => {
  return httprequest.put("/api/FailureRepairType/" + id, payload);
};

const updateTaskGroup = (id, payload) => {
  return httprequest.put("/api/TaskGroups/" + id, payload);
};

const updateTaskGroupTask = (id, payload) => {
  return httprequest.put("/api/Tasks/" + id, payload);
};

const updateTaskGroupSubTasks = (id, payload) => {
  return httprequest.put("/api/TaskGroups/subtasks/" + id, payload);
};

const updateTaskGroupParts = (id, payload) => {
  return httprequest.put("/api/TaskGroups/parts/" + id, payload);
};

const updateLocation = (id, payload) => {
  return httprequest.put("/api/Locations/" + id, payload);
};

const validateLocationCode = (code, id) => {
  return httprequest.put("/api/Locations/validate/" + code + "/?id=" + id);
};

const validatePartCode = (code, id) => {
  return httprequest.put(
    "/api/PartCatalogueItem/validate/" + code + "/?id=" + id
  );
};

const updateWarehouse = (id, payload) => {
  return httprequest.put("/api/Warehouse/" + id, payload);
};

const updateMaintenancePlanType = (id, payload) => {
  return httprequest.put("/api/MaintenancePlanTypes/" + id, payload);
};

const updateAttachmentImage = (mode, modeId, id) => {
  return httprequest.put(
    "/api/Attachment/AttachmentImage/" + mode + "/" + modeId + "/" + id
  );
};

const updateMaintenancePlanTrigger = (id, payload) => {
  return httprequest.put("/api/MaintenancePlanTrigger/" + id, payload);
};

const updateMaintenancePlanCalendarTriggger = (id, payload) => {
  return httprequest.put("/api/MaintenancePlanTrigger/calendar/" + id, payload);
};

const updateMaintenancePlanMeterTriggger = (id, payload) => {
  return httprequest.put("/api/MaintenancePlanTrigger/meter/" + id, payload);
};

const updateMaintenancePlanEventTriggger = (id, payload) => {
  return httprequest.put("/api/MaintenancePlanTrigger/event/" + id, payload);
};

const updatePlannedPart = (id, payload) => {
  return httprequest.put("/api/PlannedParts/" + id, payload);
};
const updatePlannedLabor = (id, payload) => {
  return httprequest.put("/api/PlannedLabor/" + id, payload);
};

const updateMaintenancePlanAsset = (id, payload) => {
  return httprequest.put("/api/MaintenancePlanAssets/" + id, payload);
};

const updateWorkCalendar = (payload) => {
  return httprequest.put("/api/WorkOrder/calendar/", payload);
};

const updateWorkflow = (id, payload) => {
  return httprequest.put("/api/Workflow/" + id, payload);
};

const updateWorkflowActions = (id, payload) => {
  return httprequest.put("/api/WorkflowActions/" + id, payload);
};

//Delete

const deleteWorkOrderLabour = (id) => {
  return httprequest.delete("/api/WorkLabour/" + id);
};

const deleteWorkOrderPart = (id) => {
  return httprequest.delete("/api/WorkPart/" + id);
};

const DeleteDashboard = (id) => {
  return httprequest.delete("/api/Dashboards/" + id);
};

const DeleteDashboardWidget = (id) => {
  return httprequest.delete("/api/DashboardsWidget/" + id);
};

const DeleteDashboardMapPoint = (id) => {
  return httprequest.delete("/api/DashboardsWidget/MapPoint/" + id);
};

const deleteMaintenancePlanAsset = (id) => {
  return httprequest.delete("/api/MaintenancePlanAssets/" + id);
};

const deleteSingleAsset = (id) => {
  return httprequest.delete("/api/Assets/" + id);
};

const deleteSerialStock = (payload) => {
  return httprequest.delete("/api/SerialStock/", payload);
};

const deleteAssets = (ids) => {
  return httprequest.delete("/api/Assets/", { data: ids });
};

const deleteSensor = (id) => {
  return httprequest.delete("/api/Sensor/" + id);
};

const deleteWorkOrderTasks = (ids) => {
  return httprequest.delete("/api/WorkOrderTask/multi-delete/", { data: ids });
};

const deleteSensors = (ids) => {
  return httprequest.delete("/api/Sensor/", { data: ids });
};

const deleteAsset = (payload) => {
  return httprequest.delete("/api/CrewAssets/", { data: payload });
};

const deleteMember = (id) => {
  return httprequest.delete("/api/CrewMembers/" + id);
};

const deleteAnalyticsDashboard = (params) => {
  return httprequest.delete("/api/AnalyticsDashboards/" + params);
};

const deleteAnalyticsDashboardRole = (params) => {
  return httprequest.delete("/api/AnalyticsDashboards/role/" + params);
};

const deleteAttachment = (id) => {
  return httprequest.delete("/api/Attachment/" + id);
};

const deleteGateway = (id) => {
  return httprequest.delete("/api/SensorGateways/" + id);
};

const deleteWorkOrderSubTask = (id) => {
  return httprequest.delete("/api/WorkOrderSubTasks/" + id);
};

const deleteMaintenancePlanCalendarTrigger = (id) => {
  return httprequest.delete("/api/MaintenancePlanTrigger/calendar/" + id);
};

const deleteMaintenancePlanMeterTrigger = (id) => {
  return httprequest.delete("/api/MaintenancePlanTrigger/meter/" + id);
};

const deleteMaintenancePlanEventTrigger = (id) => {
  return httprequest.delete("/api/MaintenancePlanTrigger/event/" + id);
};

const deleteMaintenancePlanTrigger = (id) => {
  return httprequest.delete("/api/MaintenancePlanTrigger/" + id);
};

const deleteWorkOrderSubTasks = (payload) => {
  return httprequest.post("/api/WorkOrderSubTasks/multi-delete", payload);
};

const deleteLastReading = (id) => {
  //const promiseArray = [];
  //promiseArray.push(httprequest.delete("/api/Meter/lastreading/" + id));
  //promiseArray.push(httprequest.delete("/api/Meter/lastreadingchild/" + id));
  //return Promise.all(promiseArray);
  return httprequest.delete("/api/Meter/lastreadingchild/" + id);
};

const deletePersonnel = (id) => {
  return httprequest.delete("/api/Personnel/" + id);
};

const deletePlannedTask = (id) => {
  return httprequest.delete("/api/PlannedTasks/" + id);
};

const deletePlannedPart = (id) => {
  return httprequest.delete("/api/PlannedParts/" + id);
};

const deletePlannedLabor = (id) => {
  return httprequest.delete("/api/PlannedLabor/" + id);
};

const deleteSubtask = (id, order) => {
  return httprequest.delete(
    "/api/TaskGroups/subtask/" + id + "?order=" + order
  );
};

const deleteLinkedSite = (id) => {
  return httprequest.delete("/api/LinkedSites/" + id);
};

const deleteUser = (id) => {
  return httprequest.delete("/api/UserDetails/" + id);
};

export default {
  GetOpenChats,
  createCrew,
  createCrewAsset,
  createCrewMembers,
  createTask,
  createWorkNotes,
  getAttachmentFile,
  getLogo,
  getLoginBackground,
  getAllAssets,
  deleteSensor,
  deleteSensors,
  deleteAsset,
  deleteAssets,
  deleteSingleAsset,
  deleteMember,
  deleteWorkOrderLabour,
  deleteWorkOrderPart,
  deleteWorkOrderTasks,
  getAssets,
  getAssertFromParentCode,
  getAssetFromCodeMain,
  getAllAssetLogs,
  getAllAssetRecentActivityLogs,
  getAttachments,
  getAllCrews,
  getCrewAssetList,
  getCrewAssets,
  getCrewMembers,
  getCostCenter,
  getCostSettlingDepartment,
  getFailureArea,
  getFailureType,
  getFailureCause,
  getFailureRepairType,
  getSubTaskTypes,
  getWorkOrderProblems,
  getWorkOrderCauses,
  getWorkOrderActions,
  getWorkOrderBoards,
  getWorkOrderStatuses,
  getCustomWorkOrderStatuses,
  getDetailedCustomWorkOrderStatuses,
  createCustomWorkOrderStatuses,
  updateCustomWorkOrderStatuses,
  getWorkOrderTypes,
  getAllUsers,
  getLeads,
  getLabourPersonnel,
  getLabourRateType,
  getMaintenancePlant,
  getMaintenanceTypes,
  getAllProjects,
  getOperationsfromgroup,
  addStock,
  addSerialStock,
  deleteSerialStock,
  updateStock,
  createAttachment,
  createWorkOrder,
  generateWorkOrder,
  getWorkOrderLabor,
  createWorkOrderLabor,
  createWorkOrderPart,
  createWorkOrderPartWh,
  getWorkOrderParts,
  getAllPersonnel,
  getTaskGroups,
  getTasksFromGroup,
  createPerson,
  updateNetwork,
  updateGateway,
  gatewayAction,
  updateAssetDetails,
  validateAssetCode,
  updateCrew,
  updatePerson,
  getWorkOrderFailure,
  createWorkOrderFailure,
  getAllSettingsUsers,
  getWorkOrderFleet,
  getWorkOrderCards,
  getWorkOrder,
  getWorkOrderCompletion,
  getWorkOrderLog,
  getWorkRequestLog,
  getPartsConsumption,
  getAssetMovement,
  getWorkOrderSubTasks,
  getWorkOrdersPrint,
  completeWorkOrderSubTasks,
  addLinkedSites,
  createWorkOrderSubTasks,
  createWorkOrderTasks,
  deleteGateway,
  deleteWorkOrderSubTask,
  deleteWorkOrderSubTasks,
  reorderSubTasks,
  reorderWorkOrderSubTasks,
  updateWorkOrderSubTasks,
  createWorkOrderFleet,
  updateWorkOrderCompletion,
  updateWorkOrderGeneralInfo,
  updateWorkOrderStatus,
  getUserRoles,
  createUser,
  updateUser,
  createAsset,
  getNonUserPersonnel,
  getAllPersonnelCrew,
  getWorkNotes,
  getWorkRequests,
  createWorkRequest,
  setEnvironment,
  updateWorkRequest,
  createWorkOrderRequest,
  getAssetFromCode,
  getUserProfiles,
  createUserRole,
  updateUserRole,
  getWorkOrderMeterReadings,
  updateMeterReadings,
  executeSSO,
  consumeSSO,
  updateWorkOrder,
  signIn,
  samlSignIn,
  logout,
  userAuthID,
  getAllSensors,
  getAxuimSensors,
  NotificationAcknowledgePost,
  Dashboard,
  getDashboard,
  GetDashboardSettings,
  getSensorWidgetData,
  getTenantSubModule,
  getUserSubModule,
  PostDashboard,
  getSensors,
  PostDashboardWidget,
  DeleteDashboard,
  DeleteDashboardWidget,
  updateDashboard,
  getTenant,
  getTenantNav,
  postHandleCredentials,
  postAdminResetCredentials,
  postValidateCredentials,
  putNewCredentials,
  updateUserProfile,
  getSensorGateways,
  getSensorGateway,
  getSensorNetwork,
  getSensorUnits,
  getGatwaysList,
  getAllMaintenancePlans,
  getAMaintenancePlan,
  updateMaintenancePlan,
  createMaintenancePlan,
  getAllMaintenancePlansAssets,
  createMaintenancePlanAsset,
  deleteMaintenancePlanAsset,
  getMeterList,
  createMeter,
  updateMeters,
  getProfileList,
  getAnalyticsDashboard,
  getAnalyticsDashboardRecord,
  deleteAnalyticsDashboardRole,
  deleteAnalyticsDashboard,
  getProfileSubModules,
  GetGatewayMessages,
  GetSensorMessages,
  createSensor,
  updateSensor,
  getSensor,
  createGateway,
  createNetwork,
  getAllPlannedTasks,
  getAllPlannedSubTasks,
  createPlannedTask,
  createPlannedSubTask,
  updatePlannedTask,
  updatePlannedSubTasks,
  createAssetClassifications,
  createAssetCategories,
  createAssetGroup1,
  createAssetGroup2,
  updateAssetClassifications,
  updateAssetCategories,
  updateAssetGroup1,
  updateAssetGroup2,
  getAllAssetClassifications,
  getAllAssetCategories,
  getAllAssetGroup1,
  getAllAssetGroup2,
  getAllAssetCategoriesParent,
  getUnitsOfMeasure,
  getChat,
  GetChatUserList,
  getAllLocationCategories,
  getAllLocationCategoriesParent,
  createLocationCategories,
  updateLocationCategories,
  getAllCostCenters,
  getAllSettlingDepartments,
  getAllMaintenanceDepartments,
  createCostCenter,
  createSettlingDepartments,
  createMaintenanceDepartment,
  updateCostCenter,
  updateSettlingDepartment,
  updateMaintenanceDepartment,
  putChat,
  getTimeToken,
  getAllWorkOrderTypes,
  updateWorkOrderType,
  createWorkOrderType,
  getAllMaintenanceTypes,
  createMaintenanceType,
  updateMaintenanceType,
  createProject,
  updateProject,
  GetContacts,
  UserData,
  ChatsData,
  GetAContact,
  deleteAttachment,
  getAllWorkOrderProblems,
  getAllWorkOrderProblemCauses,
  getAllWorkOrderActions,
  createWorkOrderProblems,
  createWorkOrderProblemCauses,
  createWorkOrderActions,
  updateWorkOrderProblems,
  updateWorkOrderProblemCauses,
  updateWorkOrderActions,
  batchCompleteWorkOrderTasks,
  updateWorkOrderTask,
  getAllPartItemCategories,
  getAllPartItemCategoriesParent,
  getAllAssetOperatingStatuses,
  getAllUnitsOfMeasure,
  createPartItemCategory,
  createAssetOperatingStatuses,
  createUnitsOfMeasure,
  updateWorkOrderLabor,
  updateWorkOrderPart,
  updatePartItemCategory,
  updateAssetOperatingStatus,
  updateUnitOfMeasure,
  getAllPartCatalogueItem,
  createPartCatalogueItem,
  updatePartCatalogueItem,
  getPartCatalogueItem,
  getPartsList,
  getAllFailureArea,
  getAllFailureCause,
  getAllFailureType,
  getAllFailureRepairType,
  getWorkOrderTask,
  updateFailureArea,
  updateFailureCause,
  updateFailureType,
  updateFailureRepairType,
  createFailureArea,
  createFailureCause,
  createFailureType,
  createFailureRepairType,
  getMeter,
  getMeterLogs,
  getMeterParents,
  createMeterReading,
  getWorkOrderLists,
  getMetersFromAsset,
  deleteLastReading,
  createTaskGroups,
  updateTaskGroup,
  getTaskGroupsTasks,
  getTaskGroupsTask,
  createTaskGroupsTask,
  createTaskGroupsTaskLabours,
  createTaskGroupsTaskParts,
  createTaskGroupsTaskSubtasks,
  updateTaskGroupTask,
  getTaskGroupsTaskSubs,
  updateTaskGroupSubTasks,
  updateTaskGroupParts,
  getAllLocations,
  getLinkedSites,
  getLocationFromCode,
  createNewLocation,
  createNewWarehouse,
  updateLocation,
  validateLocationCode,
  validatePartCode,
  updateWarehouse,
  getLocationFromId,
  getWarehouse,
  getWarehouseStock,
  getMaterialRequests,
  updateMaterialRequests,
  updateMaterialRequestStatus,
  getMaterialReceipts,
  updateMaterialReceipts,
  updateMaterialReceiptStatus,
  getMatRequestPartLocations,
  getMatReceiptPartLocations,
  getSerialStock,
  getAllAllAssets,
  getAllMaintenancePlanTypes,
  createMaintenancePlanType,
  updateMaintenancePlanType,
  updateAttachmentImage,
  getAllMaintenancePlanTriggers,
  createMaintenancePlanTrigger,
  createMaintenancePlanCalendarTrigger,
  createMaintenancePlanMeterTrigger,
  updateMaintenancePlanTrigger,
  updateMaintenancePlanCalendarTriggger,
  updateMaintenancePlanMeterTriggger,
  deleteMaintenancePlanCalendarTrigger,
  deleteMaintenancePlanMeterTrigger,
  deleteMaintenancePlanTrigger,
  deletePersonnel,
  deleteUser,
  deletePlannedTask,
  getTasksFromGroupPms,
  getAllPlannedLabor,
  getAllPlannedParts,
  createPlannedLabor,
  createPlannedPart,
  updatePlannedLabor,
  updatePlannedPart,
  deletePlannedPart,
  deletePlannedLabor,
  deleteSubtask,
  deleteLinkedSite,
  getAMaintenancePlanLogs,
  getAllMaintenancePlansAssetsQueue,
  updateMaintenancePlanAsset,
  updateMaintenancePlanEventTriggger,
  createMaintenancePlanEventTrigger,
  deleteMaintenancePlanEventTrigger,
  getNewChatsContactList,
  getNewChatsFriendList,
  createNewChatThread,
  updateNewChatThread,
  updateNewChatState,
  updateNewChatStatus,
  getWorkOrderCalendarList,
  getWorkOrderCalendarListPms,
  getUiKeys,
  updateWorkCalendar,
  getNotifications,
  getDashboardMapPoints,
  getFloorPlanPinTypes,
  getDashboardWidgetTypes,
  PostDashboardMapPoint,
  PostDashboardWidgetMap,
  DeleteDashboardMapPoint,
  getDashboardWidget,
  getTimezones,
  getSupplier,
  getSuppliersList,
  createSuppliers,
  getAllSupplierCategories,
  updateSupplier,
  createSupplierCategory,
  updateSupplierCategory,
  getWorkflowTypes,
  getWorkflowTriggers,
  getWorkRequestList,
  acknowledgeSensorAlert,
  createAnalyticsDashboard,
  createWorkflow,
  getWorkflows,
  getWorkflowActions,
  getWorkflowConditions,
  updateWorkflow,
  updateWorkflowActions,
};
